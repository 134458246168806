<template>
  <div class="d-print-none non-printable">
    <v-dialog v-model="modal" persistent :max-width="full ? '92%' : '1380'"
              class="d-print-none non-printable pa-0 ma-0"
              :content-class="dialog_id"
    >
      <list_wrapper
          :hierarchy="hierarchy"
          :list_name="list_name"
          :list_group_name="list_group_name"
          :selectable="selectable"
          :store_getter="store_getter"
          :store_group_getter="store_group_getter"
          :title="title"
          :element_title="element_title"
          :modal_wrapper="true"
          :only_groups="only_groups"
          :document="document"
          :full_screen="full_screen"
          :hide_accounting="hide_accounting"
          :parent_modal="modal"
          :alternative_header_name="alternative_header_name"
          @closeDialog="closeDialog"
          @selectItem="selectItem"
          @fullWidthDialog="fullWidthDialog"
          @closeModalEmit="closeModalEmit"
          class="d-print-none non-printable"
      />
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "list_modal",
  props: {
    dialog_id: {
      type: String,
      default() {
        return null
      }
    },
    dialog: {
      type: Boolean,
      default() {
        return false
      }
    },
    list_name: {
      type: String,
      default() {
        return ''
      }
    },
    alternative_header_name: {
      type: String,
      default() {
        return ''
      }
    },
    list_group_name: {
      type: String,
      default() {
        return ''
      }
    },
    selectable: {
      type: Boolean,
      default() {
        return false
      }
    },
    hierarchy: {
      type: Boolean,
      default() {
        return false
      }
    },
    store_getter: {
      type: String,
      default() {
        return ''
      }
    },
    store_group_getter: {
      type: String,
      default() {
        return ''
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
    element_title: {
      type: String,
      default() {
        return ''
      }
    },
    only_groups: {
      type: Boolean,
      default() {
        return false
      }
    },
    document: {
      type: Boolean,
      default() {
        return false
      }
    },
    full_screen: {
      type: Boolean,
      default() {
        return false
      }
    },
    hide_accounting: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  components: {
    list_wrapper: () => import("@/components/accounting/list_modal/list_wrapper")
  },
  data() {
    return {
      full: false,
      modal: false,
      // hide_dialog: false
    }
  },
  // computed: {
  //   ...mapGetters({
  //     accountingTabMenuItems: 'getAccountingTabMenuItems'
  //   })
  // },
  methods: {
    closeDialog() {
      this.modal = false
      this.$emit('close')
    },
    selectItem(payload) {
      this.$emit('selectItem', payload)
    },
    fullWidthDialog(payload) {
      this.full = payload
    },
    closeModalEmit(payload) {
      this.$emit('closeModalEmit', payload)
    }
  },
  watch: {
    dialog: {
      immediate: true,
      deep: true,
      handler(payload) {
        this.modal = payload
        this.full = false
      }
    },
    // accountingTabMenuItems: {
    //   immediate: true,
    //   deep: true,
    //   handler(payload) {
    //     this.hide_dialog = (payload || []).find(i => i.dialog_id === this.list_name)?.minimized
    //
    //     const els = document.getElementsByClassName(this.dialog_id)
    //     if (els.length) {
    //       const el = els[0]
    //       el.classList.toggle('hideDialogForTabMenu');
    //     }
    //   }
    // }
  }
}
</script>

<style scoped>

</style>